module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.2.0_6gkwl74cik3ygfd3puoweq6jki/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.2.0_n5y7v22mz5bv34d5s5t44qlnlq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
